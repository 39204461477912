@font-face {
  font-family: 'Chivo';
  src: url('./Chivo-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Chivo';
  src: url('./Chivo-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('./Overpass-Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('./Overpass-BoldItalic.otf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('./Overpass-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('./Overpass-Italic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
